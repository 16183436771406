import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import get from 'lodash-es/get';
import { graphql, useStaticQuery } from 'gatsby';

import WidgetTitle from '../SharedComponents/WidgetTitle';
import DataColumns from './DataColumns';

const Container = styled.div`
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 0.5rem;
  padding: 20px;

  &:first-child {
    margin-bottom: 2rem;
  }
`;

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fields: PropTypes.array,
  symbol: PropTypes.string,
  title: PropTypes.object,
  optionsTitle: PropTypes.object,
  tooltip: PropTypes.object,
  sliceType: PropTypes.string
};

const defaultProps = {
  data: {},
  fields: [],
  title: {},
  optionsTitle: {},
  tooltip: {},
  sliceType: ''
};
function ContractSpecsBlock({ fields, data, title, tooltip, sliceType }) {
  const widgetTitle = title.text && title.text;
  const { allPrismicSmallUniversalValues } = useStaticQuery(graphql`
    query UniversalValuesQuery {
      allPrismicSmallUniversalValues {
        edges {
          node {
            id
            data {
              universal_values {
                label
                label_link {
                  richText
                  text
                }
                value {
                  richText
                  text
                }
                product_type
                display_order
              }
            }
          }
        }
      }
    }
  `) || { allPrismicSmallUniversalValues: {} };

  const universalValues = get(
    allPrismicSmallUniversalValues,
    'edges[0].node.data.universal_values',
    []
  );

  const productType = sliceType === 'options_specs' ? 'options' : 'futures';
  const typeSpecificFields = universalValues.filter(
    (value) =>
      value.product_type === 'both' || value.product_type === productType
  );

  return (
    <>
      <Container>
        <WidgetTitle title={widgetTitle} tooltip={tooltip} />
        <DataColumns
          data={data}
          fields={fields}
          universalValues={typeSpecificFields}
        />
      </Container>
    </>
  );
}

ContractSpecsBlock.propTypes = propTypes;
ContractSpecsBlock.defaultProps = defaultProps;

export default ContractSpecsBlock;
