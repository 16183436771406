import React from 'react';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MarketGraphic from './MarketGraphic';
import Tooltip from '../../Tooltip';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    margin: 0 1rem;
  }
`;

const TitleContainer = styled.div`
  margin: auto;
  text-align: center;
  max-width: 784px;
  margin-bottom: 80px;
`;

const Title = styled.h1`
  font-size: 1.875rem;
  line-height: 56px;
  font-weight: ${prop('theme.fontWeights.semiBold')};
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    right: 0;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    right: -1rem;
  }
`;

const Subtitle = styled.div`
  font-size: 1rem;
`;

const GraphicLabel = styled.div`
  margin: auto;
  font-style: italic;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;

const GraphicContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 80px;

  @media (max-width: ${prop('theme.breakpoints.md')}),
    (min-width: ${prop('theme.breakpoints.lg')}) and (max-width: ${prop(
      'theme.breakpoints.xl'
    )}) {
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    margin: 0 auto;
  }

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    margin: 0;
  }
`;

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fields: PropTypes.array,
  subtitle: PropTypes.object,
  title: PropTypes.object,
  tooltip: PropTypes.object
};

const defaultProps = {
  data: {},
  fields: [],
  subtitle: {},
  title: {},
  tooltip: {}
};

function MarketComparisons({ data, fields, subtitle, title, tooltip }) {
  const widgetTitle = title.text && title.text;
  const widgetSubtitle = subtitle.text && subtitle.text;

  return (
    <Container>
      <TitleContainer>
        <Title>{widgetTitle}</Title>
        {widgetSubtitle && <Subtitle>{widgetSubtitle}</Subtitle>}
      </TitleContainer>
      {tooltip && (
        <TooltipContainer>
          <Tooltip
            content={tooltip}
            place='bottom'
            triggerValue={widgetTitle}
          />
        </TooltipContainer>
      )}
      <GraphicLabel>Capital Required:</GraphicLabel>
      <GraphicContainer>
        {fields.map((graphicData, index) => {
          return (
            <MarketGraphic
              key={`market-graphic-${index}`}
              marketData={graphicData}
              data={data}
            />
          );
        })}
      </GraphicContainer>
    </Container>
  );
}

MarketComparisons.propTypes = propTypes;
MarketComparisons.defaultProps = defaultProps;

export default MarketComparisons;
