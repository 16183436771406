import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';

import MarketComparisons from '../MarketComparisons';
import ContractSpecsBlock from '../ContractSpecsBlock';
import SiteSettingsContext from '../../../context/SiteSettingsContext';
import GatsbyIpfFileContext from '../../../context/GatsbyIpfFileContext';
import {
  DATA_SOURCE_INDEX,
  FUTURES_TO_INDEX_SYMBOL
} from '../../../utils/constants';
import MarketDataContext from '../../../context/MarketDataContext';

const propTypes = {
  fields: PropTypes.array,
  subtitle: PropTypes.object,
  symbol: PropTypes.string,
  title: PropTypes.object,
  optionsTitle: PropTypes.object,
  tooltip: PropTypes.object,
  sliceType: PropTypes.string
};

const defaultProps = {
  fields: [],
  subtitle: {},
  symbol: '',
  title: {},
  optionsTitle: {},
  tooltip: {},
  sliceType: ''
};

function WidgetFeeds({
  fields,
  subtitle,
  symbol,
  title,
  optionsTitle,
  tooltip,
  sliceType
}) {
  const siteSettings = useContext(SiteSettingsContext);
  const productData = useContext(GatsbyIpfFileContext);
  const { summaryTradeData } = useContext(MarketDataContext);

  const dataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].contract_specs_data_source) ||
    DATA_SOURCE_INDEX;

  // Have to check for the existence of the symbol because futures-only symbols won't be on an IPF file day 1.
  const frontMonthSymbol =
    !isEmpty(productData) &&
    !isEmpty(productData[symbol]) &&
    productData[symbol].frontMonthSymbol;
  const finalSymbol =
    dataSource === DATA_SOURCE_INDEX
      ? FUTURES_TO_INDEX_SYMBOL[symbol]
      : frontMonthSymbol;

  return (
    <>
      {sliceType === 'market_comparisons' ? (
        <MarketComparisons
          data={summaryTradeData[finalSymbol]}
          fields={fields}
          subtitle={subtitle}
          title={title}
          tooltip={tooltip}
        />
      ) : (
        <ContractSpecsBlock
          data={summaryTradeData[finalSymbol]}
          fields={fields}
          title={title}
          optionsTitle={optionsTitle}
          tooltip={tooltip}
          sliceType={sliceType}
        />
      )}
    </>
  );
}

WidgetFeeds.propTypes = propTypes;
WidgetFeeds.defaultProps = defaultProps;

export default WidgetFeeds;
