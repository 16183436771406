import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';

import theme from '../../../styles/theme';
import {
  capitalizeFirstLetter,
  roundToNearestHundred,
  validateKeyIsNumber
} from '../../../utils/functions';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin-right: 3rem;

  &:last-child {
    margin: 0;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}),
    (min-width: ${prop('theme.breakpoints.lg')}) and (max-width: ${prop(
      'theme.breakpoints.xl'
    )}) {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
  }

  @media (max-width: ${prop('theme.breakpoints.xxs')}) {
    font-size: 0.8125rem;
  }
`;

const LabelContainer = styled.div`
  text-align: center;
  width: fit-content;

  div {
    width: 100%;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}),
    (min-width: ${prop('theme.breakpoints.lg')}) and (max-width: ${prop(
      'theme.breakpoints.xl'
    )}) {
    order: 2;
    text-align: left;
    margin-top: 1rem;

    ${(ifProp('$left'),
    css`
      margin-left: ${prop('$left')};
    `)}
  }
`;

const Name = styled.div`
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin-bottom: 0.5rem;
`;

const SizeLabel = styled.div`
  position: relative;
  text-align: right;
  top: ${prop('$top')};
  color: ${prop('theme.colors.titleGrey')};

  @media (max-width: ${prop('theme.breakpoints.md')}),
    (min-width: ${prop('theme.breakpoints.lg')}) and (max-width: ${prop(
      'theme.breakpoints.xl'
    )}) {
    position: absolute;
    left: ${prop('$left')};
    text-align: left;
  }
`;

const ImageContainer = styled.div`
  @media (max-width: ${prop('theme.breakpoints.md')}),
    (min-width: ${prop('theme.breakpoints.lg')}) and (max-width: ${prop(
      'theme.breakpoints.xl'
    )}) {
    width: ${prop('$mobileWidth', 'fit-content')};
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    ${ifProp(
      '$mobileHeight',
      css`
        height: ${prop('$mobileHeight')};
      `
    )}
  }
`;

const Image = styled.img`
  margin-left: ${prop('marginLeft')}px;

  @media (max-width: ${prop('theme.breakpoints.md')}),
    (min-width: ${prop('theme.breakpoints.lg')}) and (max-width: ${prop(
      'theme.breakpoints.xl'
    )}) {
    margin-left: 0;
  }
`;

const propTypes = {
  marketData: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const defaultProps = {
  marketData: {},
  data: {}
};

// these are rem values
const FUTURES_LABEL_OFFSET = 0;
const ETF_LABEL_OFFSET = 6.25;
const SMALLS_LABEL_OFFSET = 3.5;
const MOBILE_FUTURES_LABEL_OFFSET = 15.5;
const MOBILE_ETF_LABEL_OFFSET = 5.375;
const MOBILE_SMALLS_LABEL_OFFSET = 4.125;

function MarketGraphic({ marketData, data }) {
  const labelRef = useRef();
  const [widthRatio, setWidthRatio] = useState(1);
  const [marginLeft, setMarginLeft] = useState(0);
  const [sizeLabelTop, setSizeLabelTop] = useState(0);
  const [imageSrc, setImageSrc] = useState(marketData.market_image);

  const name =
    (marketData &&
      marketData.market_name != null &&
      marketData.market_name.text) ||
    '';
  // Each market graphic image is sized differently, so we need specific numbers to properly offset the labels
  const desktopLabelOffset =
    name.toLowerCase() === 'futures'
      ? FUTURES_LABEL_OFFSET
      : name.toLowerCase() === 'etfs'
      ? ETF_LABEL_OFFSET
      : SMALLS_LABEL_OFFSET;
  const mobileLabelOffsetPercentage =
    name.toLowerCase() === 'futures' ? 0.65 : 0.8;

  useEffect(() => {
    if (labelRef.current) {
      setMarginLeft(labelRef.current.offsetWidth / 2);
    }
  }, [labelRef, data]);

  useEffect(() => {
    const onResize = () => {
      const xxsBreakpoint = parseInt(theme.breakpoints.xxs, 10);
      const mdBreakpoint = parseInt(theme.breakpoints.md, 10);
      const lgBreakpoint = parseInt(theme.breakpoints.lg, 10);
      const xlBreakpoint = parseInt(theme.breakpoints.xl, 10);

      if (window.innerWidth <= xxsBreakpoint) {
        // The largest image was just too big for the xxs viewport, so we needed to scale down slightly
        setWidthRatio(0.85);
      }

      const isSmallDesktopView =
        window.innerWidth > lgBreakpoint && window.innerWidth <= xlBreakpoint;

      if (window.innerWidth <= mdBreakpoint || isSmallDesktopView) {
        const imageHeight =
          marketData.mobile_image &&
          marketData.mobile_image.dimensions &&
          parseInt(marketData.mobile_image.dimensions.height, 10);
        setImageSrc(marketData.mobile_image);
        // This has to be in pixels because the imageHeight from prismic is in pixels
        setSizeLabelTop(imageHeight * mobileLabelOffsetPercentage + 'px');
      } else {
        setImageSrc(marketData.market_image);
        setSizeLabelTop(desktopLabelOffset + 'rem');
      }
    };

    if (window) {
      onResize();
      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const getSize = (field) => {
    // Checks the summary and trade data objects to see if they have a key that
    // matches the value in prismic. If there is a match it should return the
    // value, multiply it by 100 and then round to the nearest hundred
    if (field === 'price') {
      // To avoid flashing the word "price" in widget before dxfeed data loads, default to empty string.
      let value = '';

      const summary = data && data.Summary;
      const trade = data && data.Trade;
      const fieldValueClose = 'dayClose' + capitalizeFirstLetter(field);
      const fieldPrevValueClose =
        'prev' + capitalizeFirstLetter(fieldValueClose);
      if (validateKeyIsNumber(trade, field)) {
        value =
          '$' + roundToNearestHundred(trade[field] * 100).toLocaleString();
      } else if (validateKeyIsNumber(summary, fieldValueClose)) {
        value =
          '$' +
          roundToNearestHundred(
            summary[fieldValueClose] * 100
          ).toLocaleString();
      } else if (validateKeyIsNumber(summary, fieldPrevValueClose)) {
        value =
          '$' +
          roundToNearestHundred(
            summary[fieldPrevValueClose] * 100
          ).toLocaleString();
      }

      return value ? value : 'N/A';
    }

    // if field is not 'price' then it is a hard-coded dollar value
    return field;
  };

  const mobileLeftOffset = name.toLowerCase().includes('future')
    ? MOBILE_FUTURES_LABEL_OFFSET
    : name.toLowerCase().includes('etf')
    ? MOBILE_ETF_LABEL_OFFSET
    : MOBILE_SMALLS_LABEL_OFFSET;
  let size =
    (marketData && marketData.size != null && getSize(marketData.size.text)) ||
    '';
  const capitalRequiredPercentage =
    (marketData &&
      marketData.capital_required != null &&
      marketData.capital_required.text) ||
    '';
  const [low, high] = capitalRequiredPercentage.split('-').map((number) => {
    // Remove non-numeric symbols and convert to number percent
    const percent = +number.replace(/[^0-9]/g, '') * 0.01;

    return percent * +size.replace(/[^0-9]/g, '');
  });

  const dollarRange = `$${low.toLocaleString()}-${high.toLocaleString()}`;

  return (
    <Container>
      <LabelContainer
        ref={labelRef}
        $left={
          name.toLowerCase() === 'futures' ? `${-4 * widthRatio}rem` : '0.75rem'
        }
      >
        <Name>{name}</Name>
        <div>{dollarRange}</div>
        <div>({capitalRequiredPercentage})</div>
      </LabelContainer>
      <SizeLabel
        $top={sizeLabelTop}
        $left={mobileLeftOffset * widthRatio + 'rem'}
      >
        {size}
      </SizeLabel>
      <ImageContainer
        $mobileWidth={
          imageSrc &&
          imageSrc.dimensions &&
          parseInt(imageSrc.dimensions.width, 10) * widthRatio + 'px'
        }
        $mobileHeight={
          imageSrc &&
          imageSrc.dimensions &&
          parseInt(imageSrc.dimensions.height, 10) + 50 + 'px'
        }
      >
        <Image
          marginLeft={marginLeft}
          src={imageSrc && imageSrc.url}
          width={
            imageSrc &&
            imageSrc.dimensions &&
            parseInt(imageSrc.dimensions.width, 10) * widthRatio
          }
          height={
            imageSrc &&
            imageSrc.dimensions &&
            parseInt(imageSrc.dimensions.height, 10)
          }
        />
      </ImageContainer>
    </Container>
  );
}

MarketGraphic.propTypes = propTypes;
MarketGraphic.defaultProps = defaultProps;

export default MarketGraphic;
