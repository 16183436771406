import React from 'react';
import sortBy from 'lodash-es/sortBy';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../../utils/linkResolver';
import { DataRow } from './DataRow';
import {
  capitalizeFirstLetter,
  validateKeyIsNumber,
  roundToNearestHundred
} from '../../../utils/functions';

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fields: PropTypes.array,
  universalValues: PropTypes.array
};

const defaultProps = {
  data: {},
  fields: [],
  universalValues: []
};

function DataColumns({ data, fields, universalValues }) {
  const filteredUniversalValues = universalValues.filter(
    (universalValue) =>
      fields.findIndex((field) => field.label === universalValue.label) < 0
  );
  const allFields = [...fields, ...filteredUniversalValues];
  const sortedFields = sortBy(allFields, ['display_order']);

  const mapDxFeedValue = (field) => {
    let value = '';
    let finalValue = '';

    const summary = data && data.Summary;
    const trade = data && data.Trade;
    const dxfeedValue = field.dxfeed_value;
    const dxfeedPrevValue = field.dxfeed_previous_value;
    const prevValue =
      'prev' + (dxfeedPrevValue && capitalizeFirstLetter(dxfeedPrevValue));
    const isSizeLabel = field.label && field.label === 'Size';

    if (validateKeyIsNumber(trade, dxfeedValue)) {
      finalValue = isSizeLabel
        ? '$' + roundToNearestHundred(trade[dxfeedValue] * 100).toLocaleString()
        : trade[dxfeedValue];
    } else if (validateKeyIsNumber(summary, dxfeedPrevValue)) {
      finalValue = isSizeLabel
        ? '$' +
          roundToNearestHundred(summary[dxfeedPrevValue] * 100).toLocaleString()
        : summary[dxfeedValue];
    } else if (validateKeyIsNumber(summary, prevValue)) {
      finalValue = isSizeLabel
        ? '$' + roundToNearestHundred(summary[prevValue] * 100).toLocaleString()
        : summary[dxfeedPrevValue];
    }

    value = finalValue ? finalValue : 'Not Available';

    return value;
  };

  const getValue = (field) => {
    if (field.dxfeed_value) {
      return mapDxFeedValue(field);
    } else {
      return (
        <PrismicRichText
          field={field.value.richText}
          linkResolver={linkResolver}
        />
      );
    }
  };

  return (
    <>
      {sortedFields.map((field) => (
        <DataRow key={field.label} field={field} value={getValue(field)} />
      ))}
    </>
  );
}

DataColumns.propTypes = propTypes;
DataColumns.defaultProps = defaultProps;

export default DataColumns;
