import React from 'react';
import Tooltip from '../../Tooltip';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.h4`
  &&& {
    font-size: 1.125rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    padding-top: 0.625rem;
    color: ${(props) =>
      props.color
        ? prop(`theme.colors.${props.color}`)
        : prop('theme.colors.redPrimary')};

    ${ifProp(
      { withSubtitle: true },
      css`
        margin-bottom: 0;
      `
    )};
  }
`;

const propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.object,
  withSubtitle: PropTypes.bool,
  color: PropTypes.string
};

const defaultProps = {
  title: '',
  tooltip: {},
  withSubtitle: false,
  color: null
};

function WidgetTitle({ title, tooltip, withSubtitle, color }) {
  return (
    <>
      {tooltip && tooltip.text ? (
        <TitleContainer>
          <StyledTitle withSubtitle={withSubtitle} color={color}>
            {title}
          </StyledTitle>
          <Tooltip content={tooltip} triggerValue={title} place='bottom' />
        </TitleContainer>
      ) : (
        <StyledTitle withSubtitle={withSubtitle}>{title}</StyledTitle>
      )}
    </>
  );
}

WidgetTitle.propTypes = propTypes;
WidgetTitle.defaultProps = defaultProps;

export default WidgetTitle;
