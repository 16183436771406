import React from 'react';
import PropTypes from 'prop-types';

import WidgetFeeds from './WidgetFeeds';
import BlockStyles from '../../BlockStyles';

const propTypes = {
  fields: PropTypes.array,
  subtitle: PropTypes.object,
  symbol: PropTypes.string,
  title: PropTypes.object,
  optionsTitle: PropTypes.object,
  template: PropTypes.string,
  tooltip: PropTypes.object,
  sliceType: PropTypes.string
};

const defaultProps = {
  fields: [],
  primary: {},
  symbol: '',
  template: '',
  sliceType: ''
};

function WidgetWrapper({ fields, primary, symbol, template, sliceType }) {
  const { header, options_header, subheader, tooltip } = primary;

  return (
    <BlockStyles data={primary} template={template}>
      <div className='block'>
        <WidgetFeeds
          fields={fields}
          subtitle={subheader}
          symbol={symbol}
          title={header}
          optionsTitle={options_header}
          template={template}
          tooltip={tooltip}
          sliceType={sliceType}
        />
      </div>
    </BlockStyles>
  );
}

WidgetWrapper.propTypes = propTypes;
WidgetWrapper.defaultProps = defaultProps;

export default WidgetWrapper;
