import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../../utils/linkResolver';

const StyledRow = styled.div`
  display: flex;

  > * {
    border-bottom: 1px solid ${prop('theme.colors.borderGreyLight')};
    width: 50%;
  }

  &:last-child {
    > * {
      border: none;
    }
  }

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    *:first-child {
      flex: 0 0 168px;
      width: 168px;
    }

    *:last-child {
      width: 100%;
    }
  }
`;

const StyledCol = styled.div`
  font-size: 0.875rem;
  line-height: 32px;
  color: ${prop('theme.colors.black')};
  p {
    font-size: inherit;
  }

  &:first-child {
    a {
      font-weight: ${prop('theme.fontWeights.semiBold')};
      color: inherit;
    }
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-right: 0.5rem;

    @media (min-width: ${prop('theme.breakpoints.xs')}) {
      margin-right: 0.9375rem;
    }

    p,
    span {
      display: initial;
    }
  }

  strong {
    font-weight: ${prop('theme.fontWeights.bold')};
  }

  &:last-child {
    padding: 0 0.875rem 0 0.5rem;

    @media (min-width: ${prop('theme.breakpoints.xs')}) {
      padding: 0.375rem 0.9375rem;
    }
  }

  .single-digit-percent {
    padding-left: 0.6rem;
  }
`;

const Label = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export function DataRow({ field, value }) {
  return (
    <StyledRow>
      <StyledCol className='single-digit-percent'>
        <div>
          <Label>
            {field.label_link.text ? (
              <PrismicRichText
                field={field.label_link.richText}
                linkResolver={linkResolver}
              />
            ) : (
              field.label
            )}
          </Label>
        </div>
      </StyledCol>
      <StyledCol>{value}</StyledCol>
    </StyledRow>
  );
}

DataRow.propTypes = {
  field: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
